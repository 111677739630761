import { forwardRef, useMemo, useState } from 'react';
import { enums } from '@solaborate/calls';
import LocalParticipant from 'calls/LocalParticipant.js';
import RemoteHelloParticipant from 'calls/RemoteHelloParticipant.js';
import { CameraTypes, UserRoles } from 'calls/enums/index.js';
import { useConferenceConfigurations, useControllerTracks, useHelloDeviceState, useLocalParticipant } from 'calls/hooks/index.js';
import { ParticipantVideo } from 'calls/components/index.js';
import { getUserRole } from 'infrastructure/auth.js';

const DragToZoomVideo = forwardRef(
	/**
	 * @param {object} props
	 * @param {HTMLElement} props.mainRefElement
	 * @param {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} props.participant Local or remote participant
	 * @param {import('@solaborate/calls/webrtc').Cam | import('@solaborate/calls/webrtc').ScreenShare} props.activeTrackType Main participant active track type {VIDEO | SCREEN}
	 * @param {React.RefObject<HTMLVideoElement>} ref
	 */
	(
		{ mainRefElement, participant, activeTrackType },
		/** @type {React.RefObject<HTMLVideoElement>} */
		ref
	) => {
		const conferenceConfigs = useConferenceConfigurations();
		const localParticipant = useLocalParticipant();

		const tracks = useControllerTracks(
			participant instanceof LocalParticipant ? localParticipant.localTrackController : participant.remoteTrackController
		);
		const deviceState = useHelloDeviceState(participant);

		const [isDrawing, setIsDrawing] = useState(false);
		const [coordinates, setCoordinates] = useState({
			x1: 0,
			x2: 0,
			y1: 0,
			y2: 0,
		});

		const selectedCamera = useMemo(() => {
			return deviceState?.mediaDevices?.find(mediaDevice =>
				mediaDevice.isActive
			);
		}, [deviceState]);

		const getDifferenceVideoAspectRatio = () => {
			const aspectRatio = 16 / 9;
			const containerWidth = ref.current?.clientWidth;
			const containerHeight = ref.current?.clientHeight;
			const originalWidth = containerHeight * aspectRatio;
			const widthDifference = originalWidth - containerWidth;
			const originalHeight = containerWidth / aspectRatio;
			const heightDifference = originalHeight - containerHeight;
			return {
				widthDifference,
				heightDifference,
			};
		};

		const handleMouseDown = e => {
			const container = e.currentTarget.getBoundingClientRect();
			const offsetX = e.clientX - container.left;
			const offsetY = e.clientY - container.top;

			setIsDrawing(true);
			setCoordinates({
				x1: offsetX,
				x2: offsetX,
				y1: offsetY,
				y2: offsetY,
			});
		};

		const handleMouseMove = e => {
			if (!isDrawing) {
				return;
			}

			const container = e.currentTarget.getBoundingClientRect();

			const offsetX = e.clientX - container.left;
			const offsetY = e.clientY - container.top;

			setCoordinates(prevState => ({
				...prevState,
				x2: offsetX,
				y2: offsetY,
			}));
		};

		const handleMouseUp = () => {
			setIsDrawing(false);

			if (
				!ref ||
				!shouldAllowDragOrClickToMove ||
				coordinates.x1.toFixed(2) === coordinates.x2.toFixed(2) ||
				coordinates.y1.toFixed(2) === coordinates.y2.toFixed(2)
			) {
				return;
			}

			let { x1, x2, y1, y2 } = coordinates;

			const container = ref.current;
			const containerRect = container?.getBoundingClientRect();

			const containerWidth = containerRect.width;
			const containerHeight = containerRect.height;

			let width = containerWidth;
			let height = containerHeight;

			if (!conferenceConfigs.isFitScreen) {
				if (getDifferenceVideoAspectRatio().widthDifference > 0) {
					x1 += getDifferenceVideoAspectRatio().widthDifference / 2;
					x2 += getDifferenceVideoAspectRatio().widthDifference / 2;
					width = containerWidth + getDifferenceVideoAspectRatio().widthDifference;
				}
				if (getDifferenceVideoAspectRatio().widthDifference <= 0 && getDifferenceVideoAspectRatio().heightDifference > 0) {
					y1 += getDifferenceVideoAspectRatio().heightDifference / 2;
					y2 += getDifferenceVideoAspectRatio().heightDifference / 2;
					height = containerHeight + getDifferenceVideoAspectRatio().heightDifference;
				}
			}
			const x1Percentage = (x1 / width) * 100;
			const y1Percentage = (y1 / height) * 100;
			const x2Percentage = (x2 / width) * 100;
			const y2Percentage = (y2 / height) * 100;
			if (!x1 || !x2 || !y1 || !y2 || !selectedCamera?.id) {
				return;
			}
			// The MediaControlsCommands and selectedCamera?.id are diffrent from OLD to NEW(Mayo) experience
			const mediaControlsCommands = enums.MediaControlsCommands.MOVE_TO_SELECTED_POSITION
			const dataToSend = {
				x1: x1Percentage > 0 ? x1Percentage.toFixed(2) : 0,
				y1: y1Percentage > 0 ? y1Percentage.toFixed(2) : 0,
				x2: x2Percentage > 0 ? x2Percentage.toFixed(2) : 0,
				y2: y2Percentage > 0 ? y2Percentage.toFixed(2) : 0,
				cameraId: selectedCamera?.id,
			};
			participant.sendMediaControlsEvent(mediaControlsCommands, enums.MediaTypes.CAMERA, JSON.stringify(dataToSend));
		};

		const handleMouseLeave = e => {
			const { clientX, clientY } = e;
			const rect = mainRefElement?.getBoundingClientRect();

			if (clientX >= rect.left && clientX <= rect.right && clientY >= rect.top && clientY <= rect.bottom) {
				return;
			}

			setIsDrawing(false);
			setCoordinates({
				x1: 0,
				x2: 0,
				y1: 0,
				y2: 0,
			});
		};

		const shouldAllowDragOrClickToMove =
			participant instanceof RemoteHelloParticipant &&
			![UserRoles.PATIENT, UserRoles.VISITOR, UserRoles.GUEST].includes(getUserRole()) &&
			!conferenceConfigs.isGridView &&
			!conferenceConfigs.isEmbeddedView;

		const getCoordinates = e => {
			const rect = ref.current?.getBoundingClientRect();
			const containerWidth = rect.width;
			const containerHeight = rect.height;
			const aspectRatio = 16 / 9;
			const originalWidth = containerHeight * aspectRatio;
			const widthDifference = originalWidth - containerWidth;
			const originalHeight = containerWidth / aspectRatio;
			const heightDifference = originalHeight - containerHeight;
			let width = containerWidth;
			let height = containerHeight;
			let offsetX = e.clientX - rect.left;
			let offsetY = e.clientY - rect.top;
			if (!conferenceConfigs.isFitScreen) {
				if (widthDifference > 0) {
					width = containerWidth + widthDifference;
					offsetX += widthDifference / 2;
				}
				if (widthDifference <= 0 && heightDifference > 0) {
					height = containerHeight + heightDifference;
					offsetY += heightDifference / 2;
				}
			}
			const x = (offsetX / width) * 100;
			const y = (offsetY / height) * 100;
			const xFloat = parseFloat(x.toFixed(2));
			const yFloat = parseFloat(y.toFixed(2));
			return {
				x: xFloat > 0 ? xFloat : 0,
				y: yFloat > 0 ? yFloat : 0,
			};
		};

		const sendCoordinates = e => {
			if (!ref || !shouldAllowDragOrClickToMove || conferenceConfigs.isGridView) {
				return;
			}

			e.stopPropagation();

			const { x, y } = getCoordinates(e);
			if (!x || !y || !selectedCamera?.id) {
				return;
			}
			const mediaControlsCommands = enums.MediaControlsCommands.MOVE_TO_CLICKED_POSITION

			participant.sendMediaControlsEvent(
				mediaControlsCommands,
				enums.MediaTypes.CAMERA,
				JSON.stringify({
					x,
					y,
					cameraId: selectedCamera?.id,
				})
			);
		};

		const videoTrack = tracks[activeTrackType];

		let dragToZoomStyle = {
			left: `${coordinates.x2 > coordinates.x1 ? coordinates.x1 : coordinates.x2}px`,
			top: `${coordinates.y2 > coordinates.y1 ? coordinates.y1 : coordinates.y2}px`,
			width: `${coordinates.x2 > coordinates.x1 ? coordinates.x2 - coordinates.x1 : coordinates.x1 - coordinates.x2}px`,
			height: `${coordinates.y2 > coordinates.y1 ? coordinates.y2 - coordinates.y1 : coordinates.y1 - coordinates.y2}px`,
		};

		const leftOffset = ref?.current?.offsetLeft;
		const topOffset = ref?.current?.offsetTop;

		if (conferenceConfigs.isFitScreen) {
			const x1 = leftOffset > 0 ? coordinates.x1 + leftOffset : coordinates.x1;
			const x2 = leftOffset > 0 ? coordinates.x2 + leftOffset : coordinates.x2;
			const y1 = topOffset > 0 ? coordinates.y1 + topOffset : coordinates.y1;
			const y2 = topOffset > 0 ? coordinates.y2 + topOffset : coordinates.y2;

			dragToZoomStyle = {
				left: `${coordinates.x2 > coordinates.x1 ? x1 : x2}px`,
				top: `${coordinates.y2 > coordinates.y1 ? y1 : y2}px`,
				width: `${coordinates.x2 > coordinates.x1 ? coordinates.x2 - coordinates.x1 : coordinates.x1 - coordinates.x2}px`,
				height: `${coordinates.y2 > coordinates.y1 ? coordinates.y2 - coordinates.y1 : coordinates.y1 - coordinates.y2}px`,
			};
		}

		return (
			<>
				<ParticipantVideo
					ref={ref}
					track={videoTrack}
					onDoubleClick={selectedCamera?.capabilities?.moveToClickedPosition?.isSupported ? sendCoordinates : null}
					onMouseDown={shouldAllowDragOrClickToMove && selectedCamera?.type === CameraTypes.HUDDLE ? handleMouseDown : null}
					onMouseMove={shouldAllowDragOrClickToMove && selectedCamera?.type === CameraTypes.HUDDLE ? handleMouseMove : null}
					onMouseUp={selectedCamera?.capabilities?.moveToSelectedPosition?.isSupported ? handleMouseUp : null}
					onMouseLeave={shouldAllowDragOrClickToMove && selectedCamera?.type === CameraTypes.HUDDLE ? handleMouseLeave : null}
					muted
				/>
				{isDrawing && <div className='drag-zoom-square' style={dragToZoomStyle} />}
			</>
		);
	}
);

export default DragToZoomVideo;
