import { updatePatientAiSetting } from 'api/patients.js';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';
import classNames from 'classnames';
import { PatientAiSetting } from 'constants/enums.js';
import React, { useState } from 'react';
import { actionCreators as aiSettingsActionCreators } from 'state/aiSettings/actions.js';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import SoundOnAiAlertIcon from 'icons/Monitoring/SoundOnAiAlertIcon.jsx';
import AiDrawingIcon from 'components/AiDrawingIcon.jsx';
import Alert from 'components/Alert.jsx';
import translate from 'i18n-translations/translate.jsx';
import { getConfigurationValue } from 'infrastructure/helpers/commonHelpers.js';
import { AiSetting } from 'constants/configurationEnums.js';
import { AiFieldToUpdate } from 'constants/ai.js';

const AiGeneralSettings = ({ patientId, deviceId, isDarkMode, roomId, adminAiSettingsConfigurations, onAiSettingClick }) => {
	const aiSettingList = useSelector(state => state.aiSettingsList.aiSettings);
	const dispatch = useDispatch();
	const setAiSettingsAction = ai => dispatch(aiSettingsActionCreators.setPatientAiSettings(ai));
	const [error, setError] = useState(null);
	const intl = useIntl();

	const handleAiCheckboxChange = settingTypeId => {
		const aiSettingListClone = _.cloneDeep(aiSettingList);
		const foundDevice = aiSettingListClone.find(item => item.deviceId === deviceId);
		if (!foundDevice) {
			return;
		}
		const selectedSetting = foundDevice.settings.find(item => item.settingTypeId === settingTypeId);
		if (!selectedSetting) {
			return;
		}
		const isEnabled = !selectedSetting?.isEnabled;
		setAiSettingsAction({
			settings: foundDevice.settings.map(item => (item.settingTypeId === settingTypeId ? { ...item, isEnabled } : item)),
			deviceId,
		});

		updatePatientAiSettings(isEnabled, selectedSetting.id);
		onAiSettingClick(isEnabled);
	};

	const updatePatientAiSettings = async (value, id) => {
		const params = {
			patientId,
			deviceId,
			roomId,
			fieldToUpdate: AiFieldToUpdate.IS_ENABLED,
			value,
			id,
		};

		const response = await updatePatientAiSetting(params);
		if (response.error) {
			setError(response.error.message);
		}
	};

	const aiTypesToShow = [
		{
			aiTypeId: PatientAiSetting.AI_DRAWING,
			label: intl.formatMessage({ id: 'aIDetectionDrawings' }),
			img: <AiDrawingIcon color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			subTitle: intl.formatMessage({ id: 'general' }),
			noBorder: true,
			show: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.AI_DETECTION_DRAWING]),
		},
		{
			aiTypeId: PatientAiSetting.SOUND_ON_AI_ALERT,
			label: intl.formatMessage({ id: 'soundOnAiAlert' }),
			img: <SoundOnAiAlertIcon color={isDarkMode ? DarkTheme.colors.graySix : LightTheme.colors.grayFive} />,
			noBorder: true,
			show: getConfigurationValue(adminAiSettingsConfigurations[AiSetting.SOUND_ON_AI_ALERT]),
		},
	];

	const getAiTypes = () => {
		const settings = _.cloneDeep(aiSettingList);
		const found = settings.find(item => item.deviceId === deviceId);
		if (!found) {
			return [];
		}
		return found.settings;
	};

	const getAiTypeValue = aiType => getAiTypes().find(item => item.settingTypeId === aiType)?.isEnabled;

	const isOptionChecked = aiTypeId => getAiTypeValue(aiTypeId);

	return (
		<div className='ai-alerts-wrapper'>
			{aiTypesToShow.map(
				aiType =>
					aiType.show && (
						<div
							className={classNames(
								'flex flex-space-between alert-settings',
								aiType.subTitle || aiType.aiTypeId === PatientAiSetting.AI_DRAWING ? 'flex-wrap' : '',
								aiType.noBorder ? 'no-border-bottom' : '',
								aiType.isTitle ? 'title-alert-setting' : ''
							)}
							key={aiType.aiTypeId}>
							{aiType.subTitle && <span>{aiType.subTitle}</span>}
							<div className='flex flex-justify-center flex-align-center'>
								{aiType.img}
								<label className='left-s right-s'>{aiType.label}</label>
							</div>
							<div className='flex flex-align-center'>

								<label className='switch-label'>
									<div className='toggle-switch toggle-blue'>
										<input
											type={isOptionChecked(aiType.aiTypeId) ? 'checkbox' : ''}
											checked={isOptionChecked(aiType.aiTypeId)}
											onClick={() => handleAiCheckboxChange(aiType.aiTypeId)}
										/>
										<span className='toggle-body'>
											<span className='on-text'>{translate('on')}</span>
											<span className='off-text'>{translate('off')}</span>
										</span>
									</div>
								</label>
							</div>
						</div>
					)
			)}
			<Alert display={error} fixed={true} hideCloseButton={true} message={error} variant='dark' />
		</div>
	);
};

export default AiGeneralSettings;
